.social {
    text-align: right;
    img {
        margin-right: 20px
    }
    img:hover {
        opacity: 0.8;
        transition: 0.5s;
    }
}

@media (max-width: 768px) {
    .social {
        margin-top: 50px;
    }

}
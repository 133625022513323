.home {
    .logo {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 9999;
        > img {
            width: 230px;
            @media screen and (max-width: 500px) {
                width: 80px;
            }
        }
    }


    .ant-carousel .slick-slide img {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .card {
        background-color: rgba(197,202,233 ,0.5);
        padding: 40px 30px;
        margin: 30px 0px
    }
}
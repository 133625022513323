.wakely {
    margin-top: 50px;
    padding: 50px 50px;
    .brands--text {
        margin-top: 10px;
    }
    .brands {
        margin-top: 30px;
    }
    .brands > img {
        width: 100px;
        height: auto;
        margin-right: 8px;
        margin-bottom: 20px;
    }
    .youtube {
        width: 100%;
    }
    .text {
        font-size: 18px;
    }
}